<div class="overlay m-2">
    <h2 class="fw-bold" mat-dialog-title>{{ notification?.subject }}</h2>
    <mat-dialog-content class="ms-1 me-1">
        <div class="row" *ngIf="notification">
            <span><b>ID:</b> {{ notification.id }}</span>
            <span><b>Type:</b> {{ notification.type || 'Geen type' }}</span>
            <span><b>Onderwerp:</b> {{ notification.subject }}</span>
            <span><b>Status:</b> {{ notification.status }}</span>
            <span><b>Aanmaak datum:</b> {{ notification.created_at }}</span>
            <span>
                <b>Aangemaakt door:</b>
                {{ notification?.creator?.email }}
            </span>
            <span
                ><b>Datum laatst bewerkt:</b>
                {{ notification.updated_at }}</span
            >
        </div>

        <div class="row" *ngIf="notification?.receivers?.length > 0">
            <p><b>Ontvangers:</b></p>
            <div class="w-100">
                <ng-container *ngFor="let receiver of notification?.receivers">
                    <span>{{
                        receiver?.user?.email ? receiver.user.email + ', ' : ''
                    }}</span>
                </ng-container>
            </div>
        </div>

        <div class="row mt-3" *ngIf="notification?.files?.length > 0">
            <p><b>Bestanden:</b></p>
            <div class="w-100">
                <mat-list *ngFor="let file of notification.files">
                    {{ file.name }}
                    <button
                        mat-icon-button
                        (click)="download(file.path, file.name)"
                        class="light-icon"
                    >
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                </mat-list>
            </div>
        </div>

        <div class="row mt-3">
            <p><b>Verloop datum:</b> {{ notification?.status }}</p>
        </div>

        <form
            (ngSubmit)="onSubmit(notificationForm)"
            id="notificationForm"
            [formGroup]="notificationForm"
        >
            <div class="row">
                <mat-form-field appearance="fill" class="me-1">
                    <mat-label>Bericht:</mat-label>
                    <textarea
                        formControlName="message"
                        matInput
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"
                        [value]="notification?.message"
                    ></textarea>
                </mat-form-field>
            </div>
            <mat-slide-toggle formControlName="is_mail_send" color="primary">
                Brief verzonden
            </mat-slide-toggle>
            <mat-form-field appearance="fill" class="me-1">
                <mat-label>Selecteer een status</mat-label>
                <mat-select formControlName="status">
                    <mat-select-trigger>
                        <span>
                            {{ notificationForm.controls.status.value }}
                        </span>
                    </mat-select-trigger>
                    <mat-option value="Afgehandeld">
                        Afgehandeld
                        <mat-icon aria-hidden="true" class="text-success">
                            done
                        </mat-icon>
                    </mat-option>
                    <mat-option value="In behandeling">
                        In behandeling
                        <mat-icon aria-hidden="true"> timeline </mat-icon>
                    </mat-option>
                    <mat-option value="Uitgesteld">
                        Uitgesteld
                        <mat-icon>query_builder</mat-icon>
                    </mat-option>
                    <mat-option value="Ingemeten">
                        Ingemeten
                        <mat-icon class="text-danger">sms_failed</mat-icon>
                    </mat-option>
                    <mat-option value="Geweigerd">
                        Geweigerd
                        <mat-icon class="text-danger">close</mat-icon>
                    </mat-option>
                    <mat-option value="Ingepland">
                        Ingepland
                        <mat-icon class="text-success">calendar_today</mat-icon>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                class="me-1"
                appearance="fill"
                *ngIf="notificationForm.controls.status.value === 'Ingepland'"
            >
                <mat-label>Kies de verwachte tijd van de meting</mat-label>
                <input
                    matInput
                    type="datetime-local"
                    formControlName="expected_at"
                />
            </mat-form-field>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>Sluit</button>
        <button
            type="submit"
            form="notificationForm"
            mat-button
            *ngIf="isAdmin()"
        >
            Opslaan
        </button>
    </mat-dialog-actions>
</div>
