<button
    mat-mini-fab
    color="secondary"
    type="button"
    class="tool"
    matTooltip="Snap naar coördinaat"
    matTooltipPosition="left"
    (click)="getCoordinate()"
    [ngClass]="{ active: interactionService.snapGetCoordinate.active }"
>
    <fa-icon [icon]="faMapLocation"></fa-icon>
</button>
