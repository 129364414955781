import {
    Component,
    OnInit,
    OnDestroy,
    signal,
    computed,
    ChangeDetectionStrategy
} from '@angular/core';
import { NotificationDetailDialogComponent } from 'app/_dialogs/notification-detail/notification-detail.dialog';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MapService, ConfigService, LayerService, SidenavService } from 'app/_services';
import { DateAdapter } from '@angular/material/core';
import { environment } from 'environments/environment';
import GeoJSON from 'ol/format/GeoJSON';
import {
    faFilePdf,
    faMapPin,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { Feature } from 'ol';
import { MatDialog } from '@angular/material/dialog';
import { ExcelService } from 'app/_services';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'messages',
    templateUrl: 'messages.component.html',
    styleUrls: ['messages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesComponent implements OnInit, OnDestroy {
    readonly faFilePdf = faFilePdf;
    readonly faMapPin = faMapPin;
    readonly faInfoCircle = faInfoCircle;

    readonly notifications = signal([]);
    readonly filterType = signal('In behandeling');
    readonly searchInput = signal('');
    readonly geom = signal(undefined);

    readonly filteredNotifications = computed(() =>
        this.notifications().filter(
            notification =>
                (this.filterType() === 'Alles' ||
                    notification.status === this.filterType()) &&
                this.notificationMatchesSearch(notification, this.searchInput())
        )
    );

    // Type: Defines the part of the html to load
    featureType: string;
    filter: string;

    constructor(
        private mapService: MapService,
        private sidenavService: SidenavService,
        private layerService: LayerService,
        private configService: ConfigService,
        private http: HttpClient,
        private dateAdapter: DateAdapter<Date>,
        public sanitizer: DomSanitizer,
        private readonly excelService: ExcelService,
        private readonly snackBar: MatSnackBar,
        private readonly dialog: MatDialog
    ) {
        this.dateAdapter.setLocale('nl');

        this.sidenavService.setWidth(1000, 'px');
    }

    ngOnInit(): void {
        this.fetchNotifications();
    }

    ngOnDestroy(): void {
        this.layerService.notificationLayer().getSource().clear();
    }

    fetchNotifications(): void {
        const url = `${environment.api_base_url}/notifications/config/${
            this.configService.config().id
        }`;

        this.http.get(url).subscribe({
            next: (res: any[]) => {
                this.notifications.set(res);
                this.sortNotifications();
            },
            error: errors => {
                console.log(errors.error);
            }
        });
    }

    sortNotifications(): void {
        const sortedNotifications = this.notifications()
            .slice()
            .sort((a, b) => b.expected_at - a.expected_at);
        this.notifications.set(sortedNotifications);
    }

    renameKey(obj, oldKey, newKey): void {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
    }

    exportExcel(): void {
        // Filter and clone the notifications
        if (!this.notifications() || !this.filterType()) {
            console.error('Notifications or filter type is undefined');
            return;
        }

        const exportNotifications = this.notifications()
            .filter(
                n =>
                    n.status === this.filterType() ||
                    this.filterType() === 'Alles'
            )
            .map(n => ({
                ...n,
                receivers: n.receivers
                    ? n.receivers
                          .filter(receiver => receiver.user?.email)
                          .map(receiver => receiver.user.email)
                          .join(', ')
                    : ''
            }))
            .map(
                ({
                    id,
                    user_id,
                    config_id,
                    signature,
                    features,
                    files,
                    ...rest
                }) => rest
            );

        // Define the key renaming mappings
        const keyMappings: Record<string, string> = {
            status: 'Status',
            receivers: 'Ontvangers',
            subject: 'Onderwerp',
            message: 'Bericht',
            created_at: 'Aangemaakt op datum',
            updated_at: 'Laatst bewerkt op datum',
            expired_at: 'Verlopen op datum',
            expected_at: 'Verwacht op datum',
            is_mail_send: 'Is de mail verzonden'
        };

        // Rename keys in each notification object
        exportNotifications.forEach(notification => {
            for (const [oldKey, newKey] of Object.entries(keyMappings)) {
                if (notification.hasOwnProperty(oldKey)) {
                    notification[newKey] = notification[oldKey];
                    delete notification[oldKey];
                }
            }
        });

        // Export as Excel
        this.excelService.exportAsExcelFile(exportNotifications, 'Export');
    }

    /**
     * Zoom to the feature on the map
     * @param feature
     */
    loadView(id: number): void {
        const notificationLayer = this.layerService.notificationLayer();
        const url = `${environment.api_base_url}/notifications/features/${id}`;

        this.http.get(url).subscribe({
            next: res => {
                this.geom.set(res);

                if (this.geom().length < 1) {
                    this.snackBar.open('Geen geometry gevonden', 'Sluiten', {
                        panelClass: 'white-snackbar',
                        verticalPosition: 'top',
                        duration: 5000
                    });
                    return;
                }

                notificationLayer.getSource().clear();

                this.geom().forEach(feature => {
                    const geom = JSON.parse(feature.geometry);
                    const props = JSON.parse(feature.properties);
                    const newFeature = new Feature();
                    newFeature.setGeometry(
                        new GeoJSON().readGeometry(geom, {
                            featureProjection: 'EPSG:28992',
                            dataProjection: 'EPSG:4326'
                        })
                    );
                    newFeature.setProperties(props);

                    notificationLayer.getSource().addFeature(newFeature);
                    this.mapService
                        .map()
                        .getView()
                        .fit(notificationLayer.getSource().getExtent(), {
                            duration: 1000,
                            padding: [200, 200, 200, 200]
                        });
                });
            },
            error: errors => {
                console.log(errors.error);
            }
        });
    }

    openNotificationDialog(id: number): void {
        const url = `${environment.api_base_url}/notifications/detail/${id}`;

        this.http.get(url).subscribe({
            next: res => {
                const dialogRef = this.dialog.open(
                    NotificationDetailDialogComponent,
                    {
                        data: {
                            notification: res
                        },
                        panelClass: 'notification-dialog'
                    }
                );

                dialogRef.afterClosed().subscribe(() => {
                    this.fetchNotifications();
                });
            },
            error: errors => {
                console.log(errors.error);
            }
        });
    }

    download(value, name): void {
        const encodedValue = btoa(value);
        const url = `${environment.api_base_url}/download/${encodedValue}`;

        this.http
            .get(url, {
                responseType: 'blob'
            })
            .subscribe({
                next: res => {
                    value = value.substring(value.lastIndexOf('/') + 1);
                    FileSaver.saveAs(res, name);
                },
                error: errors => {
                    console.log(errors.error);
                }
            });
    }

    setFilter(filter: string) {
        this.filterType.set(filter);
    }

    clearSearchInput() {
        this.searchInput.set('');
    }

    private notificationMatchesSearch(
        notification: any,
        searchInput: string
    ): boolean {
        if (searchInput === '') {
            return true;
        }

        const lowerSearchInput = searchInput.toLowerCase();

        for (const prop in notification) {
            if (
                notification[prop] &&
                notification[prop]
                    .toString()
                    .toLowerCase()
                    .includes(lowerSearchInput)
            ) {
                return true;
            }
        }

        return false;
    }

    updateSearchInput(value: string): void {
        this.searchInput.set(value);
    }

    trackById(index, item): number {
        return item.id;
    }
}
