<!-- For custom left info grid -->
<div *ngFor="let info of data()">
    <div *ngFor="let feature of info.layers">
        <div
            class="detail"
            *ngIf="
                [
                    'werkinuitvoering_herinrichting_publiek',
                    'werkinuitvoering_gemeentelijke_projecten_publiek',
                    'werkinuitvoering_projecten_andere_overheden_publiek',
                    'werkinuitvoering_onderhoud_wegen_publiek',
                    'werkinuitvoering_onderhoud_groen_publiek',
                    'werkinuitvoering_nutsbedrijven_publiek',
                    'werkinuitvoering_projecten_derden_publiek',
                    'werkinuitvoering_recreatie_toerisme_publiek',
                    'werkinuitvoering_maatschappelijke_voorzieningen_publiek',
                    'werkinuitvoering_wegen_verkeer_publiek',
                    'werkinuitvoering_riolering_publiek',
                    'werkinuitvoering_natuur_publiek',
                    'werkinuitvoering_bomen_publiek'
                ].includes(feature.title)
            "
        >
            <p>
                <img
                    src="{{ env.subDirectory }}/assets/img/logo_{{
                        env.loginPrefix
                    }}.webp"
                />
            </p>
            <p>
                (0485) 854900 -
                <a href="mailto:gemeente@landvancuijk.nl"
                    >gemeente&commat;landvancuijk.nl</a
                >
            </p>
            <hr />
            <div *ngFor="let f of feature.features">
                <h4>{{ f.get('titel') }}</h4>
                <p>{{ f.get('omschrijving') }}</p>

                <table class="table">
                    <tr>
                        <th>Planning</th>
                        <td>
                            {{
                                f.get('planning')
                                    ? f.get('planning')
                                    : f.get('jaar_van_uitvoering')
                            }}
                        </td>
                    </tr>
                    <tr>
                        <th>Start uitvoering</th>
                        <td>{{ f.get('begindatum') }}</td>
                    </tr>
                    <tr>
                        <th>Einde uitvoering</th>
                        <td>{{ f.get('einddatum') }}</td>
                    </tr>
                </table>

                <div *ngIf="f.get('alleen_intern_weergeven') === 'true'">
                    <p>Dit project is in voorbereiding.</p>
                </div>

                <div>
                    <h4>Meer informatie</h4>
                    <p>{{ f.get('tekst_omschrijving_link') }}</p>
                    <a
                        href="{{ f.get('meer_informatie_link') }}"
                        target="_blank"
                        >{{ f.get('meer_informatie_link') }}</a
                    >
                </div>
                <hr />

                <h4>Contact</h4>

                <p>
                    {{ f.get('project_trekker') }}
                    <span *ngIf="f.get('afdeling')">-</span>
                    {{ f.get('afdeling') }}
                </p>
            </div>
        </div>

        <!-- Detail page for Land van cuijk Bouwkavels -->
        <div class="detail" *ngIf="feature.title === 'bouwkavels'">
            <div *ngFor="let f of feature.features">
                <h4>Kavelgegevens</h4>

                <div>
                    <table class="table">
                        <tr>
                            <th>Kavelnummer</th>
                            <td>{{ f.get('kavelnummer') }}</td>
                        </tr>
                        <tr>
                            <th>Oppervlakte</th>
                            <td>{{ f.get('oppervlakte') }} m2</td>
                        </tr>
                        <tr
                            *ngIf="
                                f.get('kavelstatus') !== 'Verkocht' &&
                                f.get('prijs_exbtw')
                            "
                        >
                            <th>Prijs ex. BTW</th>
                            <!-- A safe operator did not work because of the get() -->
                            <td>{{ f.get('prijs_exbtw') }}</td>
                        </tr>
                        <tr
                            *ngIf="
                                f.get('kavelstatus') !== 'Verkocht' &&
                                f.get('prijs_incbtw')
                            "
                        >
                            <th>Prijs inc. BTW</th>
                            <!-- A safe operator did not work because of the get() -->
                            <td>{{ f.get('prijs_incbtw') }}</td>
                        </tr>
                        <tr>
                            <th>Kavelstatus</th>
                            <td>{{ f.get('kavelstatus') }}</td>
                        </tr>
                    </table>
                </div>
                <h4 *ngIf="f.get('extra_informatie')">Extra informatie</h4>
                <p>{{ f.get('extra_informatie') }}</p>

                <h4>Bestemmingsplan</h4>
                <p>
                    De regels over de bebouwing op de bouwkavels zijn te vinden
                    in het bestemmingsplan. Het bestemmingsplan is te vinden op
                    de website
                    <a
                        href="http://www.ruimtelijkeplannen.nl/web-roo/roo/bestemmingsplannen?tabFilter=JURIDISCH"
                        target="_blank"
                        >www.ruimtelijkeplannen.nl</a
                    >
                </p>

                <!-- <h4>Informatie over</h4>
            <p>
              <li> <a href=" https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/algemene-verkoopvoorwaarden-van-verkoopgrond_53401.html" target="_blank">De Algemene Verkoopvoorwaarden van bouwgrond;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/woning-kopen-van-een-bouwbedrijf_40585.html" target="_blank">Het kopen van een woning van een bouwbedrijf;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/wat-is-cpo-collectief-particulier-opdrachtgeverschap_40588.html" target="_blank">Wat CPO precies inhoudt;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/procedure-verkoop-bouwkavels_40594.html" target="_blank">De procedure met betrekking tot de verkoop van een woningbouwkavel;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/onderwerpen-a-z_41397/product/omgevingsvergunning_659.html" target="_blank">Het aanvragen van een omgevingsvergunning;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/duurzaam-wonen_41628/" target="_blank">Duurzaam bouwen;</a></li><br />
            </p> -->

                <div *ngIf="f.get('pdf')">
                    <h3>Bijlage</h3>
                    <a [href]="env.base_url + f.get('pdf')" target="_blank"
                        >Download paspoort</a
                    >
                    <embed
                        [src]="env.base_url + f.get('pdf')"
                        width="260"
                        height="400"
                        type="application/pdf"
                    />
                </div>

                <h4>Contactpersoon</h4>

                <p>
                    Bedrijfsbureau Ruimte, mevrouw J. van Uden<br />
                    <a href="mailto:gemeente@landvancuijk.nl"
                        >gemeente&commat;landvancuijk.nl</a
                    >
                </p>
            </div>
        </div>
        <!-- Detail page for Land van cuijk Welstand -->
        <div
            class="detail"
            *ngIf="
                feature.title === 'welstand_grave_bebouwingstype' ||
                feature.title === 'bebouwingstype' ||
                feature.title === 'ant_welstand_bebouwingstype' ||
                feature.title === 'ensembles' ||
                feature.title === 'welstandsniveau'
            "
        >
            <div *ngFor="let f of feature.features">
                <h4 *ngIf="f.get('code')">Bebouwingstype</h4>
                <h4 *ngIf="f.get('niveau') || f.get('welstandsniveau')">
                    Welstandsniveau
                </h4>

                <div>
                    <table class="table">
                        <tr *ngIf="f.get('niveau') || f.get('welstandsniveau')">
                            <th>Welstandsniveau</th>
                            <td>
                                {{
                                    f.get('niveau') || f.get('welstandsniveau')
                                }}
                            </td>
                        </tr>
                        <tr *ngIf="f.get('code')">
                            <th>Bebouwingstype</th>
                            <td>{{ f.get('code') }}</td>
                        </tr>
                        <tr *ngIf="f.get('url')" style="margin-top: 30px">
                            <th>Beschrijving</th>
                        </tr>
                        <tr *ngIf="f.get('url')">
                            <td>
                                <a
                                    target="_blank"
                                    [href]="f.get('url')"
                                    [matTooltip]="f.get('url')"
                                    >{{ f.get('url') }}
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
                <h4 *ngIf="f.get('extra_informatie')">welstandsniveau</h4>
                <p>{{ f.get('welstand_grave_welstandsniveau') }}</p>
                <!-- <h4>Informatie over</h4>
            <p>
              <li> <a href=" https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/algemene-verkoopvoorwaarden-van-verkoopgrond_53401.html" target="_blank">De Algemene Verkoopvoorwaarden van bouwgrond;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/woning-kopen-van-een-bouwbedrijf_40585.html" target="_blank">Het kopen van een woning van een bouwbedrijf;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/wat-is-cpo-collectief-particulier-opdrachtgeverschap_40588.html" target="_blank">Wat CPO precies inhoudt;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/informatie-over-bouwkavels_41831/item/procedure-verkoop-bouwkavels_40594.html" target="_blank">De procedure met betrekking tot de verkoop van een woningbouwkavel;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/onderwerpen-a-z_41397/product/omgevingsvergunning_659.html" target="_blank">Het aanvragen van een omgevingsvergunning;</a><br />
              <li> <a href="https://www.boxmeer.nl/inwoners/duurzaam-wonen_41628/" target="_blank">Duurzaam bouwen;</a></li><br />
            </p> -->

                <!-- <div *ngIf="f.get('url')">
              <h3>Bijlage</h3>
              <a [href]="env.base_url + f.get('url')" target="_blank">Download beschrijving</a>
              <embed [src]="env.base_url + f.get('url')" width="260" height="400" type='application/pdf'>
            </div> -->

                <!-- <h4>Contactpersoon</h4>

            <p>Gemeente Land van Cuijk<br />
              <a href="mailto:gemeente@landvancuijk.nl">gemeente@landvancuijk.nl</a>
            </p> -->
            </div>

            <hr />
        </div>

        <div
            class="detail"
            *ngIf="feature.title === 'cgm_welstand_beeldkwaliteitsplannen_pdf'"
        >
            <div *ngFor="let f of feature.features">
                <h4>Beeldkwaliteitsplannen</h4>

                <div>
                    Beschrijving:
                    <a target="_blank" [href]="f.get('url')">{{
                        f.get('url')
                    }}</a>
                </div>
            </div>

            <hr />
        </div>

        <div class="detail" *ngIf="feature.title === 'log'">
            <div *ngFor="let f of feature.features">
                <h4>LOG</h4>
                <br />
                <a target="_blank" [href]="f.get('url')">{{ f.get('url') }}</a>
            </div>

            <hr />
        </div>
    </div>
</div>
