import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
    signal
} from '@angular/core';
import { environment } from 'environments/environment';
import * as FileSaver from 'file-saver';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'notification-detail-dialog',
    templateUrl: 'notification-detail.dialog.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDetailDialogComponent implements OnInit {
    notification: any;

    readonly notificationForm = new FormGroup({
        status: new FormControl(),
        is_mail_send: new FormControl(),
        expected_at: new FormControl(),
        message: new FormControl()
    });

    readonly admins = [1078, 1079, 1064, 1496, 1067];
    readonly isAdmin = signal(false);

    constructor(
        private readonly dialogRef: MatDialogRef<NotificationDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) readonly data: any,
        private readonly http: HttpClient
    ) {
        console.log(data);
        if (data.notification) {
            this.notification = data.notification;
            this.notification.created_at = new Date(
                this.notification.created_at
            ).toLocaleString('nl');
            this.notification.updated_at = new Date(
                this.notification.updated_at
            ).toLocaleString('nl');
            this.notification.expired_at = new Date(
                this.notification.expired_at
            ).toLocaleString('nl');
        }
    }

    ngOnInit(): void {
        this.notificationForm.patchValue({
            status: this.notification.status,
            is_mail_send: this.notification.is_mail_send,
            expected_at: this.notification.expected_at,
            message: this.notification.message
        });

        const id = parseInt(localStorage.getItem('id'));
        this.isAdmin.set(this.admins.includes(id));

        if (!this.isAdmin()) {
            this.notificationForm.disable();
        }
    }

    download(filePath: string, newFileName: string): void {
        const encodedValue = btoa(filePath);
        const url = `${environment.api_base_url}/download/${encodedValue}`;

        this.http
            .get(url, {
                responseType: 'blob'
            })
            .subscribe({
                next: res => {
                    filePath = filePath.substring(filePath.lastIndexOf('/') + 1);
                    FileSaver.saveAs(res, newFileName);
                },
                error: errors => {
                    console.log(errors.error);
                }
            });
    }

    onSubmit(e: any): void {
        const id = this.notification.id;
        const url = `${environment.api_base_url}/notifications/status/${id}`;

        this.http
            .put(url, {
                notification: this.notification,
                status: e.value.status,
                is_mail_send: e.value.is_mail_send,
                expected_at: e.value.expected_at,
                message: e.value.message
            })
            .subscribe({
                next: () => {
                    this.dialogRef.close();
                },
                error: errors => {
                    console.log(errors.error.error);
                    const message = errors.error.error || 'Er is iets misgegaan';
                    alert(message);
                }
            });
    }
}
