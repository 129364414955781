<div class="notifications">
    <div class="my-3">
        <h4>Meldingen</h4>
        <mat-button-toggle-group
            #filter="matButtonToggleGroup"
            class="nav-btns d-flex"
            hideSingleSelectionIndicator="true"
        >
            <mat-button-toggle value="Afgehandeld">
                <mat-icon class="text-success">done</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle
                value="Ingepland"
                (click)="setFilter('Ingepland')"
            >
                <div matTooltip="Ingepland">
                    <mat-icon class="text-success">calendar_today</mat-icon>
                </div>
            </mat-button-toggle>
            <mat-button-toggle
                value="Afgehandeld"
                (click)="setFilter('Afgehandeld')"
            >
                <div matTooltip="Afgehandeld">
                    <mat-icon class="text-success">done</mat-icon>
                </div>
            </mat-button-toggle>
            <mat-button-toggle
                value="In behandeling"
                [checked]="true"
                (click)="setFilter('In behandeling')"
            >
                <div matTooltip="In behandeling">
                    <mat-icon>timeline</mat-icon>
                </div>
            </mat-button-toggle>
            <mat-button-toggle
                value="Uitgesteld"
                (click)="setFilter('Uitgesteld')"
            >
                <div matTooltip="Uitgesteld">
                    <mat-icon class="">query_builder</mat-icon>
                </div>
            </mat-button-toggle>
            <mat-button-toggle
                value="Geweigerd"
                (click)="setFilter('Geweigerd')"
            >
                <div matTooltip="Geweigerd">
                    <mat-icon class="text-danger">close</mat-icon>
                </div>
            </mat-button-toggle>
            <mat-button-toggle
                value="Ingemeten"
                (click)="setFilter('Ingemeten')"
            >
                <div matTooltip="Ingemeten">
                    <mat-icon class="text-danger">linear_scale</mat-icon>
                </div>
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="row d-flex">
        <mat-form-field class="col">
            <!-- Add a standard input field -->
            <input
                matInput
                type="text"
                [value]="searchInput()"
                (input)="updateSearchInput($event.target.value)"
                placeholder="Zoeken"
            />

            <button
                *ngIf="searchInput()"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSearchInput()"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <div class="col">
            <button mat-raised-button (click)="exportExcel()">
                Export Excel
            </button>
        </div>
    </div>

    <mat-accordion *ngIf="notifications()?.length > 0; else noNotifications">
        <ng-container
            *ngFor="
                let notification of filteredNotifications();
                trackBy: trackById
            "
        >
            <mat-expansion-panel hideToggle style="pointer-events: none">
                <mat-expansion-panel-header class="custom-panel-header">
                    <mat-panel-title class="custom-title">
                        <mat-icon
                            *ngIf="notification.is_mail_send"
                            class="text-success mail-send-icon"
                        >
                            mail
                        </mat-icon>
                        <span>{{ notification.subject }}</span>
                    </mat-panel-title>
                    <mat-panel-description class="custom-description">
                        <span
                            class="date"
                            *ngIf="
                                notification.status === 'Ingepland' &&
                                notification?.expected_at
                            "
                        >
                            {{
                                notification.expected_at
                                    | date : 'dd-MM-yyyy hh:mm'
                            }}
                        </span>
                        <div class="btn-wrapper">
                            <button
                                role="button"
                                mat-raised-button
                                style="pointer-events: auto"
                                (click)="loadView(notification.id)"
                            >
                                <fa-icon [icon]="faMapPin"></fa-icon>
                            </button>
                            <button
                                role="button"
                                mat-raised-button
                                style="pointer-events: auto"
                                (click)="
                                    openNotificationDialog(notification.id)
                                "
                            >
                                <fa-icon [icon]="faInfoCircle"></fa-icon>
                            </button>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>
    <ng-template #noNotifications>Er zijn nog geen meldingen.</ng-template>
</div>
