<div class="message">
    <ngx-loading
        [show]="loading()"
        [config]="{ backdropBorderRadius: '14px' }"
    ></ngx-loading>

    <h4>Melding</h4>

    <form
        name="messageForm"
        class="message-form"
        (ngSubmit)="sendMessage()"
        ngForm
        #messageForm="ngForm"
        *ngIf="!messageSent()"
    >
        <fieldset>
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field>
                        <mat-label>Onderwerp</mat-label>
                        <input
                            matInput
                            placeholder="Onderwerp"
                            [(ngModel)]="message.subject"
                            name="subject"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field>
                        <mat-label>Bericht</mat-label>
                        <textarea
                            matInput
                            placeholder="Bericht"
                            [(ngModel)]="message.message"
                            name="message"
                            rows="4"
                            cols="50"
                            required
                        >
                        </textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field>
                        <mat-label>Eind datum</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker1"
                            placeholder="Eind datum"
                            [(ngModel)]="expired_at"
                            name="expire_date"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker1"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                            #picker1
                            [startAt]="expired_at"
                        ></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <mat-form-field>
                            <mat-label>Type melding</mat-label>
                            <mat-select
                                name="type"
                                [(ngModel)]="message.type"
                                placeholder="Selecteer een type"
                            >
                                <mat-option value="Infra">Infra</mat-option>
                                <mat-option value="Panden">Panden</mat-option>
                                <mat-option value="Infra/Panden"
                                    >Infra/Panden</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div *ngIf="config()?.attachments">
                <div>
                    <b>Bestanden</b>
                    <p *ngFor="let file of uploadedFiles()">
                        {{ file.name }}
                    </p>
                </div>
                <input
                    type="file"
                    class="file-input"
                    (change)="onFileSelected($event)"
                    #fileUpload
                />
                <div class="file-upload">
                    {{ uploadedFiles().length || 'Geen bestanden geupload.' }}
                    <button
                        mat-mini-fab
                        type="button"
                        color="secondary"
                        (click)="fileUpload.click()"
                    >
                        <mat-icon>attach_file</mat-icon>
                    </button>
                </div>
            </div>

            <div *ngIf="config()?.feature">
                Selecteer bestaande geometrie.
                <button
                    mat-mini-fab
                    type="button"
                    [class.active]="copyGeom()"
                    color="secondary"
                    (click)="selectGeomFromMap()"
                    matTooltip="Kopieer bestaande geometrie."
                >
                    <fa-icon [icon]="faCopy"></fa-icon>
                </button>

                <div *ngIf="feature()">
                    <b>Attributen</b>
                    <div
                        *ngFor="
                            let prop of feature().getProperties() | keyvalue
                        "
                    >
                        <p
                            *ngIf="
                                prop.value?.length &&
                                !excludedKeys.includes(prop.key)
                            "
                        >
                            <mat-checkbox
                                type="checkbox"
                                checked="true"
                                (change)="
                                    addToProps($event, prop.key, prop.value)
                                "
                            >
                                {{ prop.key }} : {{ prop.value }}
                            </mat-checkbox>
                        </p>
                    </div>
                </div>
            </div>
        </fieldset>
        <mat-divider class="mt-2 mb-2"></mat-divider>
        <button
            type="submit"
            mat-raised-button
            color="primary"
            class="float-end"
        >
            Verzenden
        </button>
    </form>

    <p *ngIf="messageSent()">Melding is verzonden!</p>
</div>
