<div class="notifications">
    <div class="my-3">
        <h4>Registraties</h4>
    </div>
    <div class="row d-flex">
        <mat-form-field class="col">
            <input
                matInput
                type="text"
                placeholder="Zoeken"
                [value]="searchInput.getValue()"
                (keyup)="applyFilter($event.target.value, 'search')"
            />
        </mat-form-field>
        <mat-form-field class="col-6">
            <mat-select
                (selectionChange)="applyFilter($event.value, 'dropdown')"
                placeholder="Status"
            >
                <mat-option value="">Alle</mat-option>
                <mat-option
                    *ngFor="let select of filterOptions()"
                    [value]="select.value"
                >
                    {{ select.value }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </div>

    <div *ngIf="dataSource()">
        <table
            mat-table
            [dataSource]="dataSource()"
            matSort
            multiTemplateDataRows
            class="mat-elevation-z8 px-2"
        >
            <ng-container matColumnDef="created_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Aangemaakt door
                </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        {{ element.created_by_name }}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="updated_at">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Laatst aangepast op
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.created_at | date }}
                </td>
            </ng-container>

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">
                    &nbsp;
                </th>
                <td mat-cell *matCellDef="let element">
                    <button
                        mat-icon-button
                        aria-label="expand row"
                        (click)="$event.stopPropagation(); openRow(element)"
                    >
                        @if (expandedElement === element) {
                        <mat-icon>keyboard_arrow_up</mat-icon>
                        } @else {
                        <mat-icon>keyboard_arrow_down</mat-icon>
                        }
                    </button>
                </td>
            </ng-container>

            <!-- Expandable Row Content -->
            <ng-container matColumnDef="expandDetail">
                <td mat-cell *matCellDef="let element" colspan="4">
                    <div
                        class="element-detail"
                        [@expand]="
                            element === expandedElement
                                ? 'expanded'
                                : 'collapsed'
                        "
                    >
                        <!-- Content + Buttons -->
                        <div class="content-wrapper">
                            <!-- Left content with form items -->
                            <div class="form-content">
                                <div *ngFor="let item of form.form_items">
                                    {{ item.name }}:
                                    <span>{{
                                        getItemValue(element, item.id)
                                    }}</span>
                                </div>
                            </div>

                            <!-- Buttons aligned to the right -->
                            <div class="button-group">
                                <button
                                    mat-icon-button
                                    (click)="openHistoryDialog(element.id)"
                                    class="light-icon"
                                >
                                    <mat-icon>history</mat-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    (click)="edit(element)"
                                    class="light-icon"
                                    [disabled]="!element.can_edit"
                                >
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button
                                    mat-icon-button
                                    disabled
                                    class="light-icon"
                                >
                                    <mat-icon
                                        matTooltip="Deze functionaliteit is in ontwikkeling."
                                        >forum</mat-icon
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Row Content -->
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumnsWithExpand"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumnsWithExpand"
                [class.example-expanded-row]="expandedElement === row"
                (click)="openRow(row)"
            ></tr>
            <tr
                mat-row
                *matRowDef="let row; columns: ['expandDetail']"
                class="example-detail-row"
            ></tr>
        </table>

        <mat-paginator
            [length]="total()"
            [pageSize]="pageSize()"
            [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator>
    </div>
</div>
