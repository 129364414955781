import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MapService, InteractionService, LayerService } from 'app/_services';
import { faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { unByKey } from 'ol/Observable';
import { Draw, Snap } from 'ol/interaction';
import * as OlSource from 'ol/source';

@Component({
    selector: 'snap-get-coordinate',
    templateUrl: './snap-get-coordinate.component.html',
    styleUrls: ['./snap-get-coordinate.component.css']
})
/**
 * This tool will get your current coordinates
 * On the right of the Map
 */
export class SnapGetCoordinateComponent {
    faMapLocation = faMapLocation;

    snackbarRef: any;

    constructor(
        private mapService: MapService,
        public snackBar: MatSnackBar,
        public interactionService: InteractionService,
        public layerService: LayerService
    ) {}

    getCoordinate(): void {
        if (this.interactionService.snapGetCoordinate.active) {
            this.interactionService.removeInteractions();
        } else {
            this.interactionService.removeInteractions();
            this.interactionService.snapGetCoordinate.active =
                !this.interactionService.snapGetCoordinate.active;
        }

        if (this.interactionService.snapGetCoordinate.active == true) {
            document.getElementById('cook_map').style.cursor = 'crosshair';
        } else {
            document.getElementById('cook_map').style.cursor = 'default';
        }

        if (
            !this.interactionService.snapGetCoordinate.interaction &&
            this.interactionService.snapGetCoordinate.active
        ) {
            const olMaps = this.mapService.map().getLayers();
            // Add snap functionality to every layer in the map
            olMaps.forEach((layer: any) => {
                if (
                    layer.getVisible() &&
                    layer.getSource() instanceof OlSource.Vector &&
                    layer.get('id')
                ) {
                    this.interactionService.interactions[layer.get('id')] = {
                        interaction: {}
                    };
                    const source = layer.getSource();
                    this.interactionService.snapGetCoordinate.snap = new Snap({
                        source,
                        pixelTolerance: 100
                    });

                    this.interactionService.interactions[
                        layer.get('id')
                    ].interaction = new Draw({
                        type: 'Point',
                        source
                    });

                    this.interactionService.interactions[
                        layer.get('id')
                    ].interaction.on('drawend', event => {
                        const feature = event.feature;
                        let geom: any = feature.getGeometry();
                        var coordinates = geom.getCoordinates();
                        this.openSnackBar(coordinates);

                        layer.getSource().removeFeature(feature);
                        this.interactionService.interactions[
                            layer.get('id')
                        ].interaction.removeLastPoint();
                        source.refresh();
                    });

                    this.mapService
                        .map()
                        .addInteraction(
                            this.interactionService.interactions[
                                layer.get('id')
                            ].interaction
                        );
                    this.mapService
                        .map()
                        .addInteraction(
                            this.interactionService.snapGetCoordinate.snap
                        );
                }
            });
        } else {
            unByKey(this.interactionService.snapGetCoordinate.interaction);
        }
    }

    openSnackBar(event): void {
        const x = Math.round(event[0] * 100) / 100;
        const y = Math.round(event[1] * 100) / 100;
        const coordinate: string = x + ' , ' + y;
        this.snackbarRef = this.snackBar.open(coordinate, 'Kopieer', {
            panelClass: 'white-snackbar',
            verticalPosition: 'top',
            duration: 10000
        });
        this.snackbarRef.onAction().subscribe(() => {
            this.copyMessage(coordinate);
        });
    }

    copyMessage(val: string): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
