import {
    Component,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy,
    EffectRef,
    effect,
    ChangeDetectorRef,
    signal,
    ChangeDetectionStrategy
} from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import GeoJSON from 'ol/format/GeoJSON';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import {
    MapService,
    ConfigService,
    LayerService,
    InteractionService
} from 'app/_services';
import { faPlus, faCopy } from '@fortawesome/free-solid-svg-icons';

export class Message {
    message: string;
    feature: any;
    attachments: any;
    image: string;
    type: string;
    subject: string;
    receivers: number[];
    config_id: number;
    status: string;
    expired_at: any;
}

@Component({
    selector: 'message',
    templateUrl: 'message.component.html',
    styleUrls: ['message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageComponent implements OnInit, OnDestroy {
    readonly faPlus = faPlus;
    readonly faCopy = faCopy;

    readonly copyGeom = signal(false);
    readonly loading = signal(false);
    readonly uploadedFiles = signal([]);
    readonly messageSent = signal(false);
    readonly feature = signal(undefined);

    readonly excludedKeys = ['geometry', 'geom', 'boundedBy', 'msGeometry'];

    message: Message;
    private readonly configSubscription: EffectRef;

    private copyInteraction: any;
    type = '';
    subject = '';
    expired_at: any;
    @Output() private readonly sent = new EventEmitter<boolean>();
    readonly typeControl = new FormControl();
    private props = {};

    readonly config = signal(undefined);
    config_id: number;

    constructor(
        private readonly http: HttpClient,
        private readonly mapService: MapService,
        private readonly layerService: LayerService,
        private readonly interactionService: InteractionService,
        private readonly configService: ConfigService,
        private readonly cdRef: ChangeDetectorRef
    ) {
        this.message = new Message();

        this.configSubscription = effect(
            () => {
                const config = this.configService.config();

                if (!config) return;

                this.config_id = config.id;
                this.config.set(config.tools.notifications);
            },
            { allowSignalWrites: true }
        );
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        this.clearCopyInteraction();
        this.clearNotificationLayer();
        this.interactionService.removeInteractions();
    }

    ngOnInit(): void {
        this.initializeConfig();
    }

    sendMessage(): void {
        this.loading.set(true);
        this.setMessageProperties();

        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/json; charset=utf-8'
        );

        const url = environment.api_base_url + '/notifications/notify';

        this.http
            .post(url, this.message, { headers, responseType: 'json' })
            .subscribe({
                next: () => {
                    this.loading.set(false);
                    this.messageSent.set(true);
                    this.sent.emit(this.messageSent());

                    setTimeout(() => {
                        this.resetForm();
                    }, 3000);
                },
                error: (error: HttpErrorResponse) => {
                    const message = error.error.error || 'Er is iets misgegaan';
                    alert(message);
                    this.loading.set(false);
                }
            });
    }

    addToProps(evt, key, value): void {
        if (evt.checked) {
            this.props[key] = value;
        } else {
            delete this.props[key];
        }
    }

    onFileSelected(event: Event): void {
        const fileInput = event.target as HTMLInputElement;
        const file: File | null = fileInput.files?.[0] || null;

        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            this.uploadFile(formData);
        }
    }

    selectGeomFromMap(): void {
        this.copyGeom.set(!this.copyGeom());

        if (this.copyGeom()) {
            this.interactionService.removeInteractions();
            this.initializeCopyInteraction();
        } else {
            this.clearCopyInteraction();
        }
    }

    private initializeConfig(): void {
        this.config.set(this.configService.config().tools.notifications);
        this.config_id = this.configService.config().id;
    }

    private setMessageProperties(): void {
        this.message.config_id = this.config_id;

        if (this.expired_at) {
            this.message.expired_at = moment(this.expired_at).format(
                'YYYY-MM-DD'
            );
        }

        this.message.attachments = this.uploadedFiles();
        this.message.receivers = this.config().receiver || [];
        this.message.status = 'In behandeling';

        if (this.config().feature && this.feature()) {
            const features = [this.feature()];
            const featureProjection = this.mapService.projection;
            this.message.feature = new GeoJSON().writeFeaturesObject(features, {
                featureProjection
            });
        }
    }

    private resetForm(): void {
        this.messageSent.set(false);
        this.message = new Message();
        this.feature.set('');
        this.expired_at = '';
        this.uploadedFiles.set([]);
        this.props = {};
        this.clearCopyInteraction();
        this.copyGeom.set(false);
        this.cdRef.detectChanges();
    }

    private uploadFile(formData: FormData): void {
        const url = environment.api_base_url + '/upload/private';

        this.http.post(url, formData).subscribe({
            next: (res: any) => {
                this.uploadedFiles.update(files => [...files, res]);
            }
        });
    }

    private clearCopyInteraction(): void {
        if (this.copyInteraction) {
            this.mapService.map().un('click', this.copyInteraction);
            this.copyInteraction = null;
        }
    }

    private clearNotificationLayer(): void {
        this.layerService.notificationLayer().getSource().clear();
    }

    private initializeCopyInteraction(): void {
        this.copyInteraction = this.mapService.map().on('click', evt => {
            this.clearNotificationLayer();

            const features: any = this.mapService
                .map()
                .getFeaturesAtPixel(evt.pixel);
            if (features.length === 0) {
                return;
            }

            const f = features[0].clone();
            this.feature.set(f);
            this.setFeatureStyle(f);
            this.layerService
                .notificationLayer()
                .getSource()
                .addFeature(this.feature());
        });
    }

    private setFeatureStyle(feature: any): void {
        const style = new Style({
            fill: new Fill({
                color: this.layerService.styleColors.magenta.fill
            }),
            stroke: new Stroke({
                color: this.layerService.styleColors.magenta.stroke,
                width: 3
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: this.layerService.styleColors.magenta.fill
                }),
                stroke: new Stroke({
                    color: this.layerService.styleColors.magenta.stroke,
                    width: 3
                })
            })
        });

        feature.setStyle(style);
    }
}
